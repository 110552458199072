.home-page {
    background: linear-gradient(135deg, #00cec9, #6c5ce7);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .home-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    text-align: center;
  }
  
  .qr-scanner-container {
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  }
  
  button {
    transition: all 0.3s ease;
  }
  
  button:hover {
    transform: translateY(-2px);
    opacity: 0.9;
  }
  