body {
    background: linear-gradient(135deg, #74b9ff, #a29bfe);
    font-family: Arial, sans-serif;
  }
  
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-card {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
  }
  
  h2 {
    color: #2d3436;
  }
  